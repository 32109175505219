import FlexContainer from "../FlexContainer";

const FixCookies = () => {
    return(
        <FlexContainer alignItems="center" center column>
            <h1 style={{color:"white"}}><h1 style={{color: "white"}}>Please allow this website to use cookies in your browsers security settings.</h1></h1>
        </FlexContainer>
    );
}

export default FixCookies;