
export const background = {
    shmeazyBlack: "#0B0E11",
    shmeazyLightBlack: "#15171F",
    shmeazyCheckedItem: "rgb(255, 255, 255, 0.1)"
}


// export const getBackground = (props: any) => {
//     if(props.shmeazyBlack) 
//         return "#0B0E11";
//     if(props.shmeazyLightBlack)
//         return "#15171F";
//     if(props.shmeazyCheckedItem)
//         return "#464646";
// }